import { Controller } from '@hotwired/stimulus'
import { localStorageKeys as keys } from '../constants'
import { get } from '@rails/request.js'

const KEY = keys['prelaunchSignedUp']

export default class NestedAttributes extends Controller {
  static targets = ['form', 'frame']

  connect() {
    this.isSignedUp
      ? this.getThanked()
      : this.showForm()
  }

  submitEnd({ detail: { success } }) {
    if (success) {
      localStorage.setItem(KEY, true)
    }
  }

  get isSignedUp() {
    const value = localStorage.getItem(KEY)
    return value !== null && value === 'true'
  }

  exposeFrame() {
    this.frameTarget.classList.remove('invisible')
  }

  showForm() {
    this.exposeFrame()
    this.formTarget.addEventListener('turbo:submit-end', this.submitEnd)
  }

  async getThanked() {
    await get('guests/thanks', { responseKind: 'turbo-stream' })
    this.exposeFrame()
  }
}
